import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import RadioGroup from 'components/global/mui4/RadioGroup';
import axios from 'axios';
import BottomBar from '../BottomBar';
import ClinicDialog from './clinicDialog';
import { API_URL } from '../../../environment';
import { headers } from '../../../actions/defaults';

const PracticeDetails0 = ({
  handleSubmit,
  openLoadingPanel,
  closeLoadingPanel,
  nextPage,
  currentStep,
  intl,
  currentSubStep,
  previousPage,
}) => {
  const [open, setOpen] = useState(false);
  const [clinicProfile, setClinicProfile] = useState(null);

  const onSubmit = values => {
    const clinicYN = values.clinic_yn;
    axios.get(`${API_URL}/practice/users/me`, headers()).then(response => {
      const resClinicProfile =
        response?.data?.clinic_counsellor_profile || null;

      if (resClinicProfile && resClinicProfile?.clinic_name) {
        setClinicProfile(resClinicProfile);
      }

      // click no and there is no existing association
      if (clinicYN === 'false' && resClinicProfile === null) {
        nextPage();
      } else {
        setOpen(true);
      }
    });
  };

  return (
    <form id="practice_detail" onSubmit={handleSubmit(onSubmit)}>
      <div className="page-title">
        <h1>
          <FormattedMessage defaultMessage="Practice Details" />
        </h1>
        {/* eslint-disable-next-line formatjs/no-literal-string-in-jsx */}
        <div className="sub-page-indicator">{`${currentSubStep + 1}/7`}</div>
      </div>
      <div style={{ marginBottom: '20px' }}>
        <h3>
          <FormattedMessage defaultMessage="Are you registering this account under a clinic?" />
        </h3>
      </div>
      <div className="input-row">
        <Field
          name="clinic_yn"
          component={RadioGroup}
          options={[
            {
              label:
                'Yes, I am registering as a practitioner under a licensed clinic',
              value: 'true',
            },
            {
              label: 'No, I am registering as an individual practitioner',
              value: 'false',
            },
          ]}
        />
      </div>
      {open && (
        <ClinicDialog
          nextPage={nextPage}
          open={open}
          setOpen={setOpen}
          clinicProfile={clinicProfile}
        />
      )}
      <BottomBar
        openLoadingPanel={openLoadingPanel}
        closeLoadingPanel={closeLoadingPanel}
        currentStep={currentStep}
        intl={intl}
        previousPage={previousPage}
      />
    </form>
  );
};

PracticeDetails0.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  openLoadingPanel: PropTypes.func.isRequired,
  closeLoadingPanel: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  currentSubStep: PropTypes.number.isRequired,
  intl: PropTypes.object.isRequired,
  previousPage: PropTypes.func,
};

const validate = values => {
  const errors = {};

  if (!values.clinic_yn)
    errors.clinic_yn = <FormattedMessage defaultMessage="Required" />;

  return errors;
};

export default injectIntl(
  reduxForm({
    form: 'PracticeOnboardingForm',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    validate,
  })(PracticeDetails0),
);
